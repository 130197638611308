import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListMonthlyReturn = () => {
    return useReadData({
        queryKey: [["totalReturns"]],
        endpoint: API.MonthlyReturn,
    });
};

export { useListMonthlyReturn };
