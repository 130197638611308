import "./desktop-menu.css";
import * as React from "react";
import Logo from "../Logo/logo.js";
import classnames from "classnames";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import Box from "@mui/joy/Box";
import ListItem from "@mui/joy/ListItem";
import Home from "@mui/icons-material/Home";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import FeedIcon from "@mui/icons-material/Feed";
import PaymentsIcon from "@mui/icons-material/Payments";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import PollIcon from "@mui/icons-material/Poll";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const styles = {
  Contact: {
    color: "#236382",
    fill: "#236382",
    fontSize: "46px",
    top: "884px",
    left: "182px",
    width: "46px",
    height: "51px",
  },
};
function IsLinkActive(to) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: "true" });
  return isActive;
}

function DesktopMenu(props) {
  return (
    <div id="main-menu-div" className={classnames(props.styleName)}>
      <div style={{ margin: "15px 20px", marginRight: "30px" }}>
        <Logo />
      </div>
      <div className="menu-category">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            gap: 2,
            flexWrap: "wrap",
            // '& > *': { minWidth: 0, flexBasis: 200 },
          }}
        >
          <List
            size="lg"
            variant="plain"
            sx={{
              maxWidth: 300,
              borderRadius: "sm",
            }}
            style={{ padding: "0 0.5rem" }}
          >
            <ListItem>
              <Link to="/" style={{ width: "100%" }} className="formatted-link">
                <ListItemButton
                  variant={IsLinkActive("/") ? "soft" : "plain"}
                  style={{ borderRadius: "25px" }}
                >
                  <ListItemDecorator>
                    <Home />
                  </ListItemDecorator>
                  Home
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem>
              <Link
                to="/notificationcenter"
                style={{ width: "100%" }}
                className="formatted-link"
              >
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={
                    IsLinkActive("/notificationcenter") ? "soft" : "plain"
                  }
                >
                  <ListItemDecorator>
                    <FeedIcon />
                  </ListItemDecorator>
                  Notification center
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem>
              <Link
                to="/profile"
                style={{ width: "100%" }}
                className="formatted-link"
              >
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/profile") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <PersonIcon />
                  </ListItemDecorator>
                  Profile
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/paymentMethod" style={{ width: "100%" }}>
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/paymentMethod") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <PaymentsIcon />
                  </ListItemDecorator>
                  Payment methods
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem>
              <Link to="/deposit" style={{ width: "100%" }}>
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/deposit") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <MoveToInboxIcon />
                  </ListItemDecorator>
                  Deposit
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem>
              <Link to="/payout" style={{ width: "100%" }}>
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/payout") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <PollIcon />
                  </ListItemDecorator>
                  Payout requests
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem>
              <Link to="/statement" style={{ width: "100%" }}>
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/statement") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <ReceiptIcon />
                  </ListItemDecorator>
                  Statement
                </ListItemButton>
              </Link>
            </ListItem>

            {/* <ListItem>
              <Link to="/AntPool" style={{ width: "100%" }}>
                <ListItemButton
                  style={{ borderRadius: "25px" }}
                  variant={IsLinkActive("/AntPool") ? "soft" : "plain"}
                >
                  <ListItemDecorator>
                    <SelectAllIcon />
                  </ListItemDecorator>
                  AntPool
                </ListItemButton>
              </Link>
            </ListItem> */}
          </List>
        </Box>
        <div className="contact-us">
          <div
            styles={{
              color: "#236382",
              fontSize: "2vw",
              fontFamily: "Source Sans Pro",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Contact Us
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div className="fake-button-menu" style={{ padding: " 0px 1rem" }}>
              <a
                title="Contact us on whatsapp"
                href="//api.whatsapp.com/send?phone=+971521009534&text=Hello, I would like to ask about "
              >
                <svg style={styles.Contact} viewBox="0 0 448 512">
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                </svg>
              </a>
            </div>
            <div className="fake-button-menu" style={{ padding: "0px 1rem" }}>
              <a title="Contact us by E-Mail" href="mailto:contact@savoie.ai">
                <svg style={styles.Contact} viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/* <Divider style={{margin:"0.7rem 1rem"}}/>
          <div style={{width:"100%", textAlign:"center", marginBottom:"1.2rem"
          }} >
            Help & Getting started
          </div> */}
      </div>
    </div>
  );
}

export default DesktopMenu;
